import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { addMonths, format, startOfMonth } from 'date-fns/fp';

import TransactionRow from './TransactionRow';
import useAffiliate from './useAffiliate';
import { caloTheme } from '../../theme/calo';
import CaloLoader from '../../components/CaloLoader';
import useAffiliateTransactions from './useAffiliateTransactions';
interface TransactionsListProps {
  userId: string;
}

const TransactionsCard = ({ userId }: TransactionsListProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const { affiliate } = useAffiliate({
    affiliateId: userId
  });

  const { transactions, transactionsLoading, hasNextPage, fetchNextPage, calculateEarnings } = useAffiliateTransactions({
    affiliateId: userId
  });

  const nextPaymentDate = format('dd/MM/yyyy')(startOfMonth(addMonths(1)(new Date())));
  const earnings = affiliate && calculateEarnings(affiliate)
  

  return (
    <Box padding={'20px 12px 20px 12px'}>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack
            width={'50%'}
            display={'flex'}
            flexDirection={'row'}
            alignItems="start"
            justifyContent={'space-between'}
          >
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Hi, {affiliate?.name} 👋
            </Typography>
          </Stack>
        </Box>
        <Box
          overflow="auto"
          width="100%"
          sx={{
            display: 'flex'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              lineHeight: '23px',
              marginX: 2
            }}
          >
            <Typography component={'span'}>Coupon Users:</Typography> {affiliate?.totalUsersAttracted}
          </Typography>

          <Divider orientation="vertical" flexItem />

          <Typography
            variant="h6"
            sx={{
              pb: 2,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              lineHeight: '23px',
              marginX: 2
            }}
          >
            <Typography component={'span'}>Earnings this month:</Typography> {earnings} {affiliate?.currency}
          </Typography>

          <Divider orientation="vertical" flexItem />

          <Typography
            variant="h6"
            sx={{
              pb: 2,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              lineHeight: '23px',
              marginX: 2
            }}
          >
            <Typography component={'span'}>Next Payment:</Typography> {nextPaymentDate}
          </Typography>
        </Box>

        <Box overflow="auto" sx={{ padding: 2 }}>
          {transactionsLoading ? (
            <Stack sx={{ width: '100%', justifyContent: 'center' }}>
              <CaloLoader />
            </Stack>
          ) : (
            <>
              <Table
                sx={{
                  marginY: '4px',
                  minHeight: '120px',
                  overflow: 'auto',
                  width: '100%',
                  gap: 16
                }}
              >
                <TableHead
                  style={{ borderRadius: '8px' }}
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    flexWrap: 0,
                    justifyContent: 'space-between',
                    width: '100%',
                    borderRadius: '8px'
                  }}
                >
                  <TableRow
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      width: '100%',
                      flexWrap: 0,
                      justifyContent: 'space-between'
                    }}
                  >
                    <StyledTableCell>Compensation Type</StyledTableCell>
                    <StyledTableCell
                      style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', width: '64px' }}
                    >
                      Transaction Type
                    </StyledTableCell>
                    <StyledTableCell>User</StyledTableCell>
                    <StyledTableCell>Transaction Date</StyledTableCell>
                    <StyledTableCell>Commission</StyledTableCell>
                    <StyledTableCell>Country</StyledTableCell>
                    <StyledTableCell>Paid Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                {transactions?.length > 0 ? (
                  <TableBody>
                    {transactions?.map((transaction) => (
                      <TransactionRow
                        affiliateTransaction={transaction}
                        affiliateCurrency={affiliate?.currency}
                        key={transaction.id}
                      />
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <Typography>NO TRANSACTIONS!</Typography>
                  </TableBody>
                )}
              </Table>
            </>
          )}
        </Box>
        {hasNextPage && (
          <Box display={'flex'} justifyContent="center">
            <Button onClick={() => fetchNextPage()}>Load Older Transactions</Button>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default TransactionsCard;
