import { TableCell, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns/fp';

import { AffiliateTransaction } from '@calo/dashboard-types';
import { Currency } from '@calo/types';

import { caloTheme } from '../../theme/calo';
import { convertCurrency } from '../../lib/utils';

interface TransactionRowProps {
  affiliateTransaction: AffiliateTransaction;
  affiliateCurrency?: string;
}

const TransactionRow = ({ affiliateTransaction, affiliateCurrency }: TransactionRowProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  const transactionDate = format('dd/MM/yyyy HH:mm a')(affiliateTransaction.date);

  const payoutCurrencies =
    affiliateTransaction.amountPaidToAffiliate && Object.keys(affiliateTransaction.amountPaidToAffiliate);
  let payoutAmount = 0;

  if (payoutCurrencies) {
    for (const currency of payoutCurrencies) {
      if (affiliateTransaction.amountPaidToAffiliate) {
        payoutAmount += convertCurrency(
          affiliateTransaction.amountPaidToAffiliate[currency as Currency],
          currency as Currency,
          affiliateCurrency as Currency
        );
      }
    }
  }
  return (
    <TableRow key={affiliateTransaction.id} hover>
      <StyledTableCell>{affiliateTransaction.compensationScheme ?? 'N/A'}</StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        sx={{
          textOverflow: 'ellipsis'
        }}
      >
        <Typography
          component="span"
          sx={{
            width: '123px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {affiliateTransaction.type.toUpperCase()}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>{affiliateTransaction.userName}</StyledTableCell>
      <StyledTableCell>{transactionDate}</StyledTableCell>
      <StyledTableCell>
        {affiliateTransaction.commissionInOriginalCurrency
          ? `${affiliateTransaction.commissionInOriginalCurrency} ${affiliateTransaction.originalCurrency}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell>{affiliateTransaction.country ? affiliateTransaction.country : 'N/A'}</StyledTableCell>
      <StyledTableCell>
        {affiliateTransaction.amountPaidToAffiliate ? `${payoutAmount} ${affiliateCurrency}` : 'N/A'}
      </StyledTableCell>
    </TableRow>
  );
};
export default TransactionRow;
