import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Affiliate } from '@calo/dashboard-types';

import { getAffiliate } from '../../actions';
interface useAffiliateTransactionsProps {
  affiliateId: string;
}

interface GetAffiliatesRes {
  affiliate: Affiliate;
}

const useAffiliate = ({ affiliateId }: useAffiliateTransactionsProps) => {
  const [affiliate, setAffiliate] = useState<Affiliate>();

  const { refetch, isLoading } = useQuery<unknown, Error, GetAffiliatesRes>(
    [`/affiliate/v1/affiliates/${affiliateId}`],
    getAffiliate,
    {
      suspense: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data) {
          setAffiliate(data.affiliate);
        }
      }
    }
  );

  return {
    affiliate,
    affiliateLoading: isLoading,
    refetchAffiliate: refetch
  };
};

export default useAffiliate;
